import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import companyLogo from "../../assets/images/building.png";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDImage,
  TXDInput,
  TXDSelect,
} from "@supporttxend/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import {
  PORTFOLIO_ADDRESS_ADD_RQ,
  PORTFOLIO_CONTACT_MOD_RQ,
} from "@/services/api/userService";
import { IPlanSponosrModalProps } from "@/interfaces";
import SaveSpinner from "../skeletons/SaveSpinner";
import { STATE_PROVINCE } from "@/constants/enumConstants";
import { loadGoogleMapsScript } from "@/utils/componentUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "../skeletons/toastUtils";
const UpdateCompanyInfoModal: React.FC<IPlanSponosrModalProps> = ({
  modalOpen,
  closeModal,
  userProfile,
  onUpdate,
}: any) => {
  const [loading, setLoading] = useState(false);

  // const [addressValue, setAddressValue] = useState(
  //   userProfile.InternalPortfolio?.PortfolioAddress1 == "" ||
  //     userProfile.InternalPortfolio?.PortfolioAddress1 === undefined
  //     ? ""
  //     : userProfile.InternalPortfolio?.PortfolioAddress1
  // );
  const [currentValue, setCurrentValues] = useState<any>({
    fullAddress: userProfile.InternalPortfolio?.PortfolioAddress1,
    city: userProfile.InternalPortfolio?.PortfolioCity,
    country: userProfile.InternalPortfolio?.PortfolioCountry,
    postalCode: userProfile.InternalPortfolio?.PortfolioPostalCode,
    stateProvince: userProfile.InternalPortfolio?.PortfolioStateProv,
  });

  const accessCode = useSelector(
    (state: RootState) => state.auth.AccessCode as string
  );

  useEffect(() => {
    if (modalOpen) {
      const initializeGoogle = async () => {
        try {
          await loadGoogleMapsScript("address", setCurrentValues);
        } catch (error) {
          // console.log(error);
        }
      };
      initializeGoogle();
    }
  }, [modalOpen, currentValue.fullAddress]);

  // console.log(currentValue);

  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
    input10: false,
  });

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const phoneNo = e.target[3].value;
    const email = e.target[4].value;
    const streetAddress = e.target[5].value;
    const streetAddress2 = e.target[6].value;
    const stateProvincs = e.target[7].value;
    const city = e.target[8].value;
    const zipCode = e.target[9].value;
    const formattedPhone = phoneNo.replace(/\D/g, "");
    // Check if any input has an error
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.INPUTS_ERROR);
      setLoading(false);
      return;
    } else {
      toast
        .promise(
          Promise.all([
            PORTFOLIO_ADDRESS_ADD_RQ(
              {
                PortfolioPermId: userProfile.Portfolio.PortfolioPermId,
                Addr1: streetAddress,
                Addr2: streetAddress2,
                City: city,
                StateProv: stateProvincs,
                PostalCode: Number(zipCode),
                Country: userProfile.InternalPortfolio?.PortfolioCountry,
                AddressTypePermId: 100,
                IsPrimary: true,
              },
              accessCode
            ),
            PORTFOLIO_CONTACT_MOD_RQ(
              {
                PortfolioPermId: userProfile.Portfolio.PortfolioPermId,
                EmailAddr: email,
                EmailAddr2:
                  userProfile.InternalPortfolio?.PortfolioEmailAddress2,
                Phone: formattedPhone,
                PhoneType: formattedPhone.length,
                EmpEmailAddr: "",
              },
              accessCode
            ),
          ]),
          {
            loading: LOADING_MESSAGES.PROFILE_LOADING,
            success: <b>{SUCCESS_MESSAGES.PROFILE_ADDED}</b>,
            error: <b>{ERROR_MESSAGES.PROFILE_FAILED}</b>,
          }
        )
        .then(() => {
          onUpdate();
          closeModal();
          setLoading(false);
        })
        .catch((error: any) => {
          toast.error(ERROR_MESSAGES.PROFILE_FAILED || error?.message);
          setLoading(false);
        });
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0  flex items-center justify-center z-50"
          onClose={() => {
            //Do nothing
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <TXDBox className="inline-block w-full max-w-5xl my-8 mx-12 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <TXDFlex justifyContent="center" alignItems="center">
                <TXDBox className="modalOverlay w-full">
                  <TXDBox className="fixed top-0 left-0 right-0 z-30 bg-white">
                    <TXDFlex
                      alignItems="center"
                      className="p-4 flex justify-between border-b"
                    >
                      <TXDText
                        align="left"
                        as="h1"
                        className="uppercase  text-gray-500 sm:text-xl text-md whitespace-nowrap "
                        size="xl"
                        weight="normal"
                      >
                        EDIT COMPANY CONTACT
                      </TXDText>

                      <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                          onClick={closeModal}
                        />
                      </TXDBox>
                    </TXDFlex>
                    <TXDFlex
                      variant="column"
                      className="sm:flex-row flex-col text-center justify-between bg-gray-100 py-4 px-4 md:px-10"
                    >
                      <TXDFlex
                        justifyContent="center"
                        alignItems="center"
                        className="  gap-4 mb-2 md:mb-0"
                      >
                        <TXDImage
                          display="inline-block"
                          radius="none"
                          src={companyLogo}
                          variant="cover"
                          className="h-10 w-auto"
                        />
                        <TXDText
                          align="left"
                          as="h1"
                          className="uppercase text-gray-500 sm:text-sm text-xs"
                          size="sm"
                          weight="normal"
                        >
                          COMPANY NAME
                        </TXDText>
                        <TXDText
                          as="h1"
                          className=" sm:pl-4 pl-0 text-gray-400 sm:text-sm text-xs"
                          size="sm"
                          weight="normal"
                        >
                          {userProfile?.InternalPortfolio?.PortfolioLegalName ||
                            "N/A"}
                        </TXDText>
                      </TXDFlex>

                      <TXDFlex
                        justifyContent="center"
                        alignItems="center"
                        className="  gap-4"
                      >
                        <TXDText
                          align="left"
                          as="h1"
                          className="uppercase text-gray-500 sm:text-sm text-xs"
                          size="sm"
                          weight="normal"
                        >
                          ID
                        </TXDText>
                        <TXDText
                          as="h1"
                          className=" text-gray-400 sm:text-sm text-xs"
                          size="sm"
                          weight="normal"
                        >
                          {userProfile?.Portfolio?.PortfolioPermId || "N/A"}
                        </TXDText>
                      </TXDFlex>
                    </TXDFlex>
                  </TXDBox>

                  <TXDBox
                    className="mt-10 pt-28 pb-32 px-10 overflow-y-auto grid grid-cols-1 md:grid-cols-2 participant-table "
                    style={{ maxHeight: "calc(100vh - 160px)" }}
                  >
                    {/* 1st */}
                    {/* <TXDFlex
                      variant="column"
                      alignItems="center"
                      className=" gap-2 "
                    > */}

                    {/* <TXDButton className=" font-normal text-xs text-gray-500 uppercase bg-transparent hover:bg-transparent z-0 ">
                        <FontAwesomeIcon
                          icon={faImage}
                          className="h-3 w-3 opacity-60   text-gray-400"
                        />
                        &nbsp; Change Photo
                      </TXDButton> */}
                    {/* </TXDFlex> */}
                    {/* 2nd */}
                    <TXDBox>
                      <form
                        className=" w-full "
                        id="profileForm"
                        onSubmit={handleSubmit}
                      >
                        <TXDInput
                          className="pl-0"
                          id="companyName"
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          placeholder="Enter Company Name"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          initialValue={
                            userProfile.InternalPortfolio?.PortfolioLegalName
                          }
                          label
                          labelText="Company Name"
                          regex={REGEX_CONSTANTS.ADMINISTRATIVE_FIRM_REGEX}
                          required
                          filled={true}
                          disabled
                        />
                        <TXDInput
                          className="pl-0"
                          id="firstName"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="First Name"
                          placeholder="Enter first name"
                          filled={true}
                          disabled
                          sizing="xs"
                          type="text"
                          variant="styled"
                          initialValue={
                            userProfile.InternalPortfolio?.PortfolioFirstName
                          }
                          regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
                          required
                          errorExist={(error: any) =>
                            updateErrorState("input1", error ? true : false)
                          }
                        />
                        <TXDInput
                          className="pl-0"
                          id="lastName"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Last Name"
                          placeholder="Enter last name"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          filled={true}
                          disabled
                          initialValue={
                            userProfile.InternalPortfolio?.PortfolioLastName
                          }
                          regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
                          required
                          errorExist={(error: any) =>
                            updateErrorState("input2", error ? true : false)
                          }
                        />
                        <TXDInput
                          id="phoneNumber"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Phone Number"
                          placeholder="Enter phone number"
                          sizing="xs"
                          type="tel"
                          variant="styled"
                          initialValue={
                            userProfile.InternalPortfolio?.PortfolioPhone
                          }
                          regex={REGEX_CONSTANTS.PHONE_REGEX}
                          required
                          errorExist={(error: any) => {
                            updateErrorState("input3", error ? true : false);
                          }}
                        />
                        <TXDInput
                          id="email"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Email Address"
                          placeholder="Enter email address"
                          sizing="xs"
                          type="email"
                          variant="styled"
                          initialValue={
                            userProfile.InternalPortfolio
                              ?.PortfolioEmailAddress1
                          }
                          regex={REGEX_CONSTANTS.EMAIL_REGEX}
                          required
                          errorExist={(error: any) => {
                            updateErrorState("input4", error ? true : false);
                          }}
                        />
                        <TXDInput
                          id="address"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Street Address"
                          placeholder="Enter street address"
                          sizing="xs"
                          variant="styled"
                          value={currentValue.fullAddress}
                          onInput={(e: any) => {
                            setCurrentValues({
                              ...currentValue,
                              fullAddress: e.target.value || "",
                            });
                            // setAddressValue(e.currentTarget.value);
                          }}
                          required
                          // onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          //   console.log(e.currentTarget.value);
                          //   setAddressValue(e.currentTarget.value);
                          // }}
                          errorExist={(error: any) => {
                            updateErrorState("input5", error ? true : false);
                          }}
                        />
                        <TXDBox hidden>
                          <TXDInput
                            id="addressTwo"
                            label
                            labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                            labelText="Street Address 2 (Optional)"
                            placeholder="Enter street address 2"
                            sizing="xs"
                            variant="styled"
                            className="mb-1 pl-0"
                            initialValue={
                              userProfile.InternalPortfolio
                                ?.PortfolioAddress2 == "" ||
                              userProfile.InternalPortfolio
                                ?.PortfolioAddress2 === undefined
                                ? ""
                                : userProfile.InternalPortfolio
                                    ?.PortfolioAddress2
                            }
                          />
                        </TXDBox>

                        <TXDSelect
                          className="external-downimg pl-0"
                          id="selectCountry"
                          isObjectArray
                          label
                          onChange={(e: any) => {
                            setCurrentValues({
                              ...currentValue,
                              stateProvince: e.target.value || "",
                            });
                          }}
                          value={currentValue.stateProvince}
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="State or Province"
                          optionsArray={STATE_PROVINCE["US"].map((state) => ({
                            label: state.name || state.label,
                            value: state.state_code || state.value,
                            disabled: state.disabled,
                          }))}
                          sizing="xs"
                          variant="styled"
                          // initialValue={
                          //   userProfile.InternalPortfolio?.PortfolioCity
                          // }
                          required
                        />
                        <TXDInput
                          id="selectCity"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer  text-xs "
                          variant="styled"
                          className="pl-0"
                          labelText={"City"}
                          placeholder="Enter city name"
                          type="text"
                          sizing="xs"
                          minLength={2}
                          maxLength={30}
                          value={currentValue.city}
                          onInput={(e: any) => {
                            setCurrentValues({
                              ...currentValue,
                              city: e.target.value || "",
                            });
                          }}
                          regex={REGEX_CONSTANTS.CITY_REGEX}
                          errorExist={(error: any) =>
                            updateErrorState("input5", error ? true : false)
                          }
                          required
                        />
                        <TXDInput
                          id="zipCode"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Zip or Postal Code"
                          placeholder="Enter zip or postal code"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          className="pl-0"
                          value={currentValue.postalCode}
                          onInput={(e: any) => {
                            setCurrentValues({
                              ...currentValue,
                              postalCode: e.target.value || "",
                            });
                          }}
                          regex={REGEX_CONSTANTS.ZIP_CODE_REGEX}
                          required
                          errorExist={(error: any) => {
                            updateErrorState("input6", error ? true : false);
                          }}
                        />
                        <TXDFlex
                          justifyContent="end"
                          // className="gap-4 pb-8 px-8 mt-4  fixed bottom-0 left-0 right-0 flex-col sm:flex-row z-30 bg-white"
                          className="gap-4 px-4 py-4 bg-white fixed bottom-0 left-0 right-0 flex-col-reverse sm:flex-row z-30"
                        >
                          <TXDButton
                            className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 sm:w-[115px]"
                            radius="full"
                            // size="md"
                            variant="secondary"
                            border={false}
                            onClick={closeModal}
                          >
                            Cancel
                          </TXDButton>
                          <TXDButton
                            className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                            radius="full"
                            // size="lg"
                            variant="primary"
                            type="submit"
                          >
                            {loading ? <SaveSpinner /> : "SAVE"}
                          </TXDButton>
                        </TXDFlex>
                      </form>
                    </TXDBox>
                    {/* 3rd */}
                    <TXDBox className="flex sm:pl-10 pl-2">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-4 w-4 mr-2 mt-1 leading-10 opacity-60 text-blue-600"
                      />
                      <TXDText
                        as="p"
                        className="text-gray-500 pb-2 md:pb-10"
                        size="sm"
                        weight="normal"
                      >
                        To change any outdated or incorrect Information, simply
                        type over it and then selecting the Saves button. Fields
                        marked with an <span className="text-red-600">*</span>{" "}
                        are required fields.
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                </TXDBox>
              </TXDFlex>
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpdateCompanyInfoModal;
