import React from "react";
import { TXDBox, TXDFlex, TXDText } from "@supporttxend/txd-components";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import NavRightSkeleton from "@/layouts/skeletons/NavRightSkeleton";
import { INavRightSection } from "@/interfaces";

const NavRightSection: React.FC<INavRightSection> = ({ data }) => {
  const { userProfile, loading } = data;

  const totalAssets = userProfile
    ? `$${userProfile.InternalPortfolio?.PortfolioAssetsLineage.toFixed(2)}`
    : "$0.00";

  const formatParticipantCount = (count: number) => {
    if (count === 0) {
      return "--";
    }
    return count < 10 ? `0${count}` : count;
  };

  const totalParticipants = useSelector(
    (state: RootState) => state.participants.totalParticipants
  );
  const formattedParticipants = formatParticipantCount(totalParticipants);

  const dataArray = [
    {
      key: "TOTAL ASSETS",
      value: totalAssets,
      icon: "increase",
    },
    {
      key: "TOTAL PARTICIPANTS",
      value: <span className="flex">{formattedParticipants}</span>,
      icon: "increase",
    },
  ];

  return (
    <>
      <TXDBox className="overflow-hidden hidden bg-[#FFFFFF] md:grid lg:grid xl:grid grid-cols-3 col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-7 sm:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 md:divide-y-0">
        {loading ? (
          <TXDFlex className="col-span-3">
            <NavRightSkeleton />
          </TXDFlex>
        ) : (
          dataArray.map((data, index) => (
            <TXDFlex
              key={index}
              variant="column"
              justifyContent="center"
              alignItems="center"
            >
              <TXDFlex variant="column">
                <TXDFlex variant="row">
                  <TXDText
                    as="span"
                    className="text-gray-900 uppercase sm:text-[10px] md:text-[10px] lg:text-xs xl:text-xs"
                    size="xs"
                    weight="bold"
                  >
                    {/* {data.key} */}
                  </TXDText>
                  <TXDText
                    as="span"
                    className="ml-2 hidden sm:hidden md:hidden lg:inline-flex xl:inline-flex cursor-pointer"
                  >
                    {/* <FontAwesomeIcon
                      className={
                        data.icon === "increase"
                          ? "text-[#22C55E]"
                          : "text-[#FD9199]"
                      }
                      icon={data.icon === "increase" ? faCaretUp : faCaretDown}
                    /> */}
                  </TXDText>
                </TXDFlex>
                <TXDBox>
                  <TXDText
                    as="span"
                    className="text-gray-500 sm:text-md md:text-lg lg:text-xl xl:text-2xl"
                    weight="normal"
                  >
                    {/* {data.value} */}
                  </TXDText>
                </TXDBox>
              </TXDFlex>
            </TXDFlex>
          ))
        )}
      </TXDBox>
    </>
  );
};

export default NavRightSection;
