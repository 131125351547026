import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import companyLogo from "../../assets/images/building.png";
import {
  TXDText,
  TXDBox,
  TXDFlex,
  TXDImage,
} from "@supporttxend/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import ShowCompanyInfoSkeleton from "@/layouts/skeletons/CompanyInfoModalSkeleton";
import { IShowCompanyModalProps } from "@/interfaces";
import {
  findBusinessLabelByValue,
  findStateNameByCode,
  formatResponsePhoneNumber,
  getValueOrDefault,
  transformTaxIdentifier,
} from "@/utils/componentUtils";
import useFetchProfile from "@/services/userProfile";

const ShowCompanyInfoModal: React.FC<IShowCompanyModalProps> = ({
  modalOpen,
  closeModal,
  identifier,
}: any) => {
  const { userProfile, loading } = useFetchProfile(identifier);
  // console.log(userProfile?.InternalPortfolio?.PortfolioBusinessClass);
  const ProfileObject = {
    "TAX IDENTIFIER": getValueOrDefault(
      userProfile?.InternalPortfolio?.PortfolioTaxIdentifier,
      transformTaxIdentifier
    ),
    "Company Name": getValueOrDefault(
      userProfile?.InternalPortfolio?.PortfolioLegalName
    ),
    "BUSSINESS TYPE": getValueOrDefault(
      findBusinessLabelByValue(
        userProfile?.InternalPortfolio?.PortfolioBusinessClass
      )
    ),
    "STREET ADDRESS": getValueOrDefault(
      userProfile?.InternalPortfolio?.PortfolioAddress1
    ),
    CITY: getValueOrDefault(userProfile?.InternalPortfolio?.PortfolioCity),
    STATE: getValueOrDefault(
      findStateNameByCode(userProfile?.InternalPortfolio?.PortfolioStateProv)
    ),
    "ZIP CODE": getValueOrDefault(
      userProfile?.InternalPortfolio?.PortfolioPostalCode
    ),
    "CONTACT FIRST NAME": getValueOrDefault(userProfile?.Portfolio?.FirstName),

    "CONTACT LAST NAME": getValueOrDefault(userProfile?.Portfolio?.LastName),
    "CONTACT PHONE NUMBER": getValueOrDefault(
      formatResponsePhoneNumber(userProfile?.InternalPortfolio?.PortfolioPhone)
    ),
    // formatResponsePhoneNumber(userProfile?.InternalPortfolio?.PortfolioPhone)
    "CONTACT EMAIL ADDRESS": getValueOrDefault(
      userProfile?.InternalPortfolio?.PortfolioEmailAddress1
    ),
  };

  const companyName = getValueOrDefault(
    userProfile?.InternalPortfolio?.PortfolioLegalName
  );

  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0  flex items-center justify-center z-50"
          onClose={() => {
            //Do Nothing
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 "
          >
            <TXDBox className="inline-block w-full max-w-2xl my-8 mx-12  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg ">
              {loading ? (
                <ShowCompanyInfoSkeleton />
              ) : (
                <TXDFlex justifyContent="center" alignItems="center">
                  <TXDBox className="modalOverlay w-full">
                    <TXDBox className="bg-white">
                      <TXDFlex
                        alignItems="center"
                        className="p-4 flex justify-between   "
                      >
                        <TXDText
                          align="left"
                          as="h1"
                          className="uppercase text-gray-500 sm:text-xl text-sm whitespace-nowrap "
                          size="xl"
                          weight="normal"
                        >
                          Company Details
                        </TXDText>
                        <TXDBox
                          className="tooltip-wrapper"
                          data-tooltip="Close"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                            onClick={closeModal}
                          />
                        </TXDBox>
                      </TXDFlex>

                      <TXDFlex
                        variant="column"
                        className=" sm:flex-row flex-col text-center justify-between bg-gray-100 py-4 px-4 md:px-4"
                      >
                        <TXDFlex
                          justifyContent="center"
                          alignItems="center"
                          className="  gap-4 mb-2 md:mb-0"
                        >
                          <TXDImage
                            display="inline-block"
                            radius="none"
                            src={companyLogo}
                            variant="cover"
                            className="h-10 w-auto"
                          />
                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase text-gray-500"
                            size="sm"
                            weight="normal"
                          >
                            COMPANY NAME
                          </TXDText>
                          <TXDText
                            as="h1"
                            className=" sm:pl-4 pl-0 text-gray-400"
                            size="sm"
                            weight="normal"
                          >
                            {companyName}
                          </TXDText>
                        </TXDFlex>

                        <TXDFlex
                          justifyContent="center"
                          alignItems="center"
                          className="  gap-4"
                        >
                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase text-gray-500"
                            size="sm"
                            weight="normal"
                          >
                            ID
                          </TXDText>
                          <TXDText
                            as="h1"
                            className=" text-gray-400"
                            size="sm"
                            weight="normal"
                          >
                            {userProfile.InternalPortfolio.PortfolioPermId}
                          </TXDText>
                        </TXDFlex>
                      </TXDFlex>
                    </TXDBox>

                    <TXDBox
                      className="pb-10 px-10 overflow-y-auto grid grid-cols-1 md:grid-cols-1  participant-table "
                      style={{ maxHeight: "calc(100vh - 160px)" }}
                    >
                      {/* 1st */}
                      {/* <TXDFlex
                        variant="column"
                        alignItems="center"
                        className=" gap-2 "
                      > */}

                      {/* <TXDButton className=" font-normal text-xs text-gray-500 uppercase bg-transparent hover:bg-transparent z-0 ">
                          <FontAwesomeIcon
                            icon={faImage}
                            className="h-3 w-3 opacity-60   text-gray-400"
                          />
                          &nbsp; Change Photo
                        </TXDButton> */}
                      {/* </TXDFlex> */}
                      {/* 2nd */}

                      <TXDBox>
                        {/* Block 1 */}
                        <TXDBox className="mt-5 space-y-2">
                          {Object.entries(ProfileObject).map(
                            ([key, label], index) => {
                              return (
                                <TXDBox
                                  key={`${key}-${index}`}
                                  className=" gap-2 grid grid-cols-2"
                                >
                                  <TXDBox>
                                    <TXDText
                                      as="h2"
                                      className="text-gray-400  text-xs uppercase"
                                      size="sm"
                                      weight="normal"
                                    >
                                      {key}
                                    </TXDText>
                                  </TXDBox>
                                  <TXDBox>
                                    <TXDText
                                      as="h2"
                                      className="text-gray-500  text-xs"
                                      size="sm"
                                      weight="normal"
                                    >
                                      {label}
                                    </TXDText>
                                  </TXDBox>
                                </TXDBox>
                              );
                            }
                          )}
                        </TXDBox>
                      </TXDBox>

                      {/* 3rd */}
                      <TXDBox className="sm:pl-10 pl-2 ">
                        {/* <FontAwesomeIcon
                          icon={faCircleExclamation}
                          className="h-4 w-4 opacity-60 text-gray-400"
                        /> */}
                        {/* <TXDText
                          as="p"
                          className="text-gray-500  pb-2 md:pb-10  "
                          size="sm"
                          weight="normal"
                        >
                          To change any outdated or incorrect Information,
                          simply type over it and then selecting the Saves
                          button. Fields marked with an * are required fields.
                        </TXDText> */}
                        {/* <TXDFlex
                        justifyContent="end"
                        className="gap-4 pb-8 px-8  mt-4  fixed bottom-0 left-0 right-0 flex-col sm:flex-row z-30 bg-white"
                      >
                        <TXDButton
                          className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 w-auto"
                          radius="full"
                          size="md"
                          variant="secondary"
                          border={false}
                        >
                          Cancel
                        </TXDButton>

                        <TXDButton
                          className="bg-white text-[#00AFD8] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#00AFD8] hover:bg-[#00AFD8] hover:text-white hover:ring-[#00AFD8] w-auto"
                          radius="full"
                          size="lg"
                          variant="primary"
                        >
                          Save
                        </TXDButton>
                      </TXDFlex> */}
                      </TXDBox>
                    </TXDBox>
                  </TXDBox>
                </TXDFlex>
              )}
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShowCompanyInfoModal;
