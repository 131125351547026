import { rolePageId, userPolicyId } from "@/constants/constant";
import {
  BUSINESS_TYPES,
  CODING_REASONS,
  MARTIAL_STATUS,
  STATE_PROVINCE,
  STATUS_CODE_MAP,
  TRANSACTION_ENUMS,
} from "@/constants/enumConstants";
import { ITransformFunction } from "@/interfaces";
import { ERROR_MESSAGES } from "@/layouts/skeletons/toastUtils";

export const formatPhoneNumber = (phoneNumber: string) => {
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6)}`;
};

export const getFullName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};

export const transformTaxIdentifier = (identifier: string) => {
  return `${identifier.slice(0, 2)}-${identifier.slice(2, 5)}${identifier.slice(
    5
  )}`;
};

export const fieldMapping: { [key: string]: string } = {
  PortfolioLegalName: "COMPANY NAME",
  PortfolioTaxIdentifier: "IDENTIFIER",
  PortfolioAddress1: "ADDRESS",
  PortfolioFirstName: "ADMINISTRATOR",
  PortfolioPhone: "PHONE NUMBER",
  PortfolioEmailAddress1: "EMAIL ADDRESS",
};

export const underlineFirstFourLetters = (name: string) => {
  if (!name) return "";
  const firstFourLetter = name.slice(0, 4);
  const restLetter = name.slice(4);

  return { firstFourLetter: firstFourLetter, restLetter: restLetter };
};

export const getValueOrDefault = (
  value: any,
  transformation: ITransformFunction = (val) => val
) => {
  return value ? transformation(value) : "--";
};

export const formatSocialSecurity = (ssn: string) => {
  return `xxxx-xx-${ssn.slice(-4)}`;
};

export const getHumanReadableStatus = (
  statusCode: number | undefined
): string => {
  if (statusCode === undefined) {
    return "Unknown Status";
  }
  const description = STATUS_CODE_MAP[statusCode];
  return description || `Unknown Status (${statusCode})`;
};

export const getPageId = (policyId: any) => {
  switch (policyId) {
    case userPolicyId.TPAPolicy:
      return rolePageId.TPADashboard;
    case userPolicyId.PAPolicy:
      return rolePageId.PADashboard;
    case userPolicyId.PSPolicy:
      return rolePageId.PSDashboard;
    default:
      return 1000;
  }
};

export const validateAge = (dateString: string, minAge = 18): string => {
  const selectedDate = new Date(dateString);
  const today = new Date();
  let age = today.getFullYear() - selectedDate.getFullYear();
  const month = today.getMonth() - selectedDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < selectedDate.getDate())) {
    age--;
  }

  if (age < minAge) {
    return `You must be at least ${minAge} years old.`;
  } else {
    return "";
  }
};

// src/utils/componentUtils.ts
// export const loadGoogleMapsScript = (apiKey: string, callback: () => void) => {
//   const existingScript = document.getElementById("googleMaps");

//   if (!existingScript) {
//     const script = document.createElement("script");
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
//     script.id = "googleMaps";
//     script.async = true;
//     script.defer = true;
//     document.body.appendChild(script);

//     script.onload = () => {
//       // console.log("Google Maps script loaded successfully");
//       callback();
//     };

//     script.onerror = () => {
//       console.error("Failed to load Google Maps script");
//     };
//   } else {
//     callback();
//   }
// };

export const loadGoogleMapsScript = (
  input: string,
  state: React.Dispatch<React.SetStateAction<string>>
) => {
  const promise = new Promise((resolve, reject) => {
    // asynchronous operation
    const existingScript = document.getElementById("googleMaps");
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      console.error(ERROR_MESSAGES.GOOGLE_API_FAILED);
      reject(false);
    }
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.id = "googleMaps";
      script.async = true;
      script.defer = true;
      script.setAttribute("loading", "lazy");
      document.body.appendChild(script);
      script.onload = () => {
        initializeAutocomplete(input, state);
        resolve(true);
      };

      script.onerror = () => {
        console.error(ERROR_MESSAGES.GOOGLE_API_FAILED);
        reject(false);
      };
    } else {
      if (window.google && window.google.maps) {
        initializeAutocomplete(input, state);
        resolve(true);
      } else {
        console.error(ERROR_MESSAGES.GOOGLE_API_FAILED);
        reject(false);
      }
    }
  });
  return promise;
};

const parsePlaceResult = (place: any) => {
  // Initialize the object to return
  const parsedAddress = {
    fullAddress: place.formatted_address || "",
    street: "",
    sublocality: "",
    city: "",
    stateProvince: "",
    postalCode: "",
    country: "",
  };

  // Loop through the address components and extract the relevant fields
  place.address_components.forEach((component: any) => {
    const types = component.types;

    if (types.includes("route")) {
      // Street Address
      parsedAddress.street = component.long_name;
    }

    if (types.includes("sublocality_level_1")) {
      // Sublocality (like neighborhoods or districts)
      parsedAddress.sublocality = component.long_name;
    }

    if (types.includes("locality")) {
      // City
      parsedAddress.city = component.long_name;
    }

    if (types.includes("administrative_area_level_1")) {
      // State/Province
      parsedAddress.stateProvince = component.short_name;
    }

    if (types.includes("postal_code")) {
      // ZIP/Postal Code
      parsedAddress.postalCode = component.long_name;
    }

    if (types.includes("country")) {
      // Country
      parsedAddress.country = component.long_name;
    }
  });

  return parsedAddress;
};

// countryRestriction: string = 'us'
export const initializeAutocomplete = async (
  inputId: string,
  setAddressValue: (value: any) => void
) => {
  if (window.google && window.google.maps) {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    if (inputElement) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputElement,
        {
          types: ["address"],
        }
      );

      // console.log("Autocomplete initialized:", autocomplete);

      autocomplete.addListener("place_changed", async () => {
        const place = await autocomplete.getPlace();
        if (place) {
          let formattedAddress = place.formatted_address || "";

          if (!formattedAddress && place.address_components) {
            formattedAddress = place.address_components
              .map((ac: any) => ac.long_name)
              .join(", ");
          }

          inputElement.value = formattedAddress;
          // console.log("here is the address field => ", parsePlaceResult(place));
          setAddressValue(parsePlaceResult(place));

          setTimeout(() => {
            inputElement.blur();
          }, 100);

          // console.log("Selected place:", place);
        } else {
          console.error(ERROR_MESSAGES.GOOGLE_API_ERROR);
        }
      });
    } else {
      console.error(ERROR_MESSAGES.GOOGLE_API_ERROR);
    }
  } else {
    console.error(ERROR_MESSAGES.GOOGLE_API_ERROR);
  }
};

export const findBusinessLabelByValue = (value: string) => {
  const businessType = BUSINESS_TYPES.find((item) => item.value == value);
  return businessType ? businessType.label : "--";
};

export const findStateNameByCode = (state_code: string) => {
  const stateName = STATE_PROVINCE.US.find(
    (item) => item.state_code == state_code
  );
  return stateName ? stateName.name : "--";
};

export const findTransactionStatus = (value: string) => {
  const transactionStatus = TRANSACTION_ENUMS.find(
    (item) => item.value == value
  );
  return transactionStatus
    ? { name: transactionStatus.name, badge: transactionStatus.badge }
    : { name: "--", badge: "--" };
};

export const findStateCode = (state_code: string) => {
  console.log(state_code);
  const stateName = STATE_PROVINCE.US.find(
    (item) => item.state_code == state_code
  );
  return stateName ? stateName.state_code : "";
};

export const formatResponsePhoneNumber = (number: any) => {
  if (number) {
    const cleaned = number.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return number; // Return null if the input is not a valid 10-digit number
  } else {
    return "--";
  }
};

export const findMaritalStatusName = (portfolioMaritalPermId: any) => {
  const maritalStatus = MARTIAL_STATUS.find(
    (item) => item.value == portfolioMaritalPermId
  );
  return maritalStatus ? maritalStatus.name : "--";
};

export const findDescriptionByCodingReason = (codingReason: any) => {
  const result = CODING_REASONS.find(
    (item) => item.CodingReason === codingReason
  );
  return result ? result.Description : "CodingReason not found";
};
