import React from "react";
import rolloverLogo from "../assets/images/logo-rollover.png";
import {
  TXDBox,
  TXDImage,
  TXDFlex,
  TXDText,
} from "@supporttxend/txd-components";

const SignUpFooter: React.FC = () => {
  return (
    <footer className="py-10 mt-20 flex justify-center footer__img z-10 px-4">
      <TXDBox className="container">
        <TXDFlex variant="row" justifyContent="center" className="py-10">
          <TXDImage
            src={rolloverLogo}
            alt="Website Logo"
            className="sm:w-96 w-64"
          />
        </TXDFlex>
        <TXDFlex variant="row" className="justify-start sm:justify-center">
          <TXDBox className="lg:px-48 grid grid-cols-3 px-20 sm:grid-cols-3 md:grid-cols-6 lg:gap-12 xl:gap-10 sm:gap-5 md:gap-10">
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText as="span" className="font-bold text-[#4B6795]">
                  About Us
                </TXDText>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText as="span" className="font-bold text-[#4B6795]">
                  Professionals
                </TXDText>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText as="span" className="font-bold text-[#4B6795]">
                  Individulas
                </TXDText>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText as="span" className="font-bold text-[#4B6795]">
                  Contact Us
                </TXDText>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText as="span" className="font-bold text-[#4B6795]">
                  Terms & Conditions
                </TXDText>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText as="span" className="font-bold text-[#4B6795]">
                  Privacy Policy
                </TXDText>
              </TXDFlex>
            </TXDBox>
          </TXDBox>
        </TXDFlex>
        <TXDBox className="py-4 my-4 footer__descp">
          <p>
            The investments you make through your Rolloversaver® account are not
            federally insured, are not guarantees or obligations of
            Rolloversaver®, UMB Bank and may involve risk, including possible
            loss of principal.
          </p>

          <p>
            self-directed investment platform offered by Rolloversaver®.
            Rolloversaver® is not affiliated with any advisor whose name appears
            on the Rolloversaver® website. The information contained in the
            InvestedHealth® website should not be considered to be either a
            recommendation by Rollloversaver® or a solicitation of any offer to
            purchase or sell any securities.
          </p>
          <p>
            Rolloversaver® is not a registered investment adviser and does not
            provide investment or financial advice. Please consult with your
            personal tax or financial advisor.
          </p>
        </TXDBox>
      </TXDBox>
    </footer>
  );
};

export default SignUpFooter;
