import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-solid-svg-icons";
import { TXDText, TXDBox, TXDFlex } from "@supporttxend/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import useFetchTransactionDetails from "@/services/useFetchTransacationDetails";
import TransactionSkeleton from "@/layouts/skeletons/TransactionSkeleton";
import { IShowTransactionModalProps } from "@/interfaces";
import {
  findDescriptionByCodingReason,
  findTransactionStatus,
} from "@/utils/componentUtils";

const ShowTransacationModal: React.FC<IShowTransactionModalProps> = ({
  modalOpen,
  closeModal,
  portfolioPermId,
  transactionPermId,
}: any) => {
  const { transactionDetail, loading } = useFetchTransactionDetails(
    portfolioPermId,
    transactionPermId
  );

  const formatDate = (dateString: any) => {
    if (!dateString || isNaN(Date.parse(dateString))) return "N/A";
    return new Date(dateString).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0  flex items-center justify-center z-50"
          onClose={() => {
            //Do nothing
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 "
          >
            <TXDBox className="inline-block w-full max-w-7xl my-8 mx-12 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              {loading ? (
                <TransactionSkeleton />
              ) : (
                <TXDFlex
                  justifyContent="center"
                  className="participant-table pt-20"
                  alignItems="center"
                  style={{ maxHeight: "70vh", overflowY: "auto" }}
                >
                  <TXDBox className="modalOverlay w-full overflow-y-scroll">
                    <TXDBox className="fixed top-0 left-0 right-0 z-30 bg-white">
                      <TXDFlex
                        alignItems="center"
                        className=" flex justify-between border border-gray-100 p-4"
                      >
                        <TXDBox className="w-full flex items-center gap-6">
                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase text-gray-500 sm:text-xl text-md"
                            size="xl"
                            weight="normal"
                          >
                            TRANSACTIONS
                          </TXDText>
                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase text-gray-500 sm:text-xl text-md"
                            size="xl"
                            weight="normal"
                          >
                            {
                              transactionDetail?.TransactionData
                                ?.TransactionPermId
                            }
                          </TXDText>
                        </TXDBox>
                        <TXDBox
                          className="tooltip-wrapper"
                          data-tooltip="Close"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none`}
                            onClick={closeModal}
                          />
                        </TXDBox>
                      </TXDFlex>
                    </TXDBox>
                    <TXDBox className="shadow-sm">
                      <TXDFlex className="bg-white" variant="column">
                        <TXDBox className="grid grid-cols-12 px-4 sm:px-8 gap-4 md:gap-8">
                          <TXDFlex
                            variant="column"
                            className="gap-5 col-span-12 md:col-span-6 w-full"
                          >
                            <TXDBox className="flex sm:flex-col gap-20 sm:gap-0 flex-row">
                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase"
                                size="sm"
                                weight="bold"
                              >
                                TRANSACTION
                              </TXDText>
                              <TXDText
                                as="span"
                                className="text-gray-500 sm:text-xl text-md"
                                weight="normal"
                              >
                                {transactionDetail?.TransactionData?.Desc}
                              </TXDText>
                            </TXDBox>
                          </TXDFlex>
                          <TXDFlex className="col-span-12 sm:col-span-4 md:col-span-3 sm:flex-col flex-row gap-28 sm:gap-0">
                            <TXDBox>
                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase"
                                size="sm"
                                weight="bold"
                              >
                                Amount
                              </TXDText>
                            </TXDBox>
                            <TXDBox className="flex flex-col">
                              <TXDText
                                as="span"
                                className="text-gray-500 sm:text-xl text-md"
                                weight="normal"
                              >
                                +$
                                {transactionDetail?.TransactionData?.Amount.toLocaleString()}
                              </TXDText>

                              <TXDText
                                as="span"
                                className="text-gray-900 sm:mt-2"
                                size="sm"
                                weight="normal"
                              >
                                Units:{" "}
                                {transactionDetail?.TransactionData?.Units.toLocaleString()}
                              </TXDText>
                            </TXDBox>
                          </TXDFlex>
                          <TXDFlex className="sm:col-span-3 col-span-12">
                            <TXDBox className="flex-row sm:flex-col gap-32 sm:gap-0 flex w-full">
                              <TXDBox className="">
                                <TXDText
                                  as="span"
                                  className="text-gray-900 uppercase"
                                  size="sm"
                                  weight="bold"
                                >
                                  STATUS
                                </TXDText>
                              </TXDBox>
                              <TXDBox className="flex flex-col">
                                <TXDText
                                  as="span"
                                  className="text-gray-500 sm:text-lg text-md"
                                  size="xl"
                                  weight="normal"
                                >
                                  {
                                    findTransactionStatus(
                                      transactionDetail?.TransactionData?.Status
                                    ).name
                                  }
                                </TXDText>
                              </TXDBox>
                            </TXDBox>
                          </TXDFlex>
                        </TXDBox>

                        <TXDBox className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 border-t border-gray-200 p-4 sm:p-8  pl-4 gap-4 md:gap-8">
                          <TXDFlex variant="column" className=" gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              ADD DATE
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              REQUEST DATE
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              TRADE DATE
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              EFFECTIVE DATE
                            </TXDText>
                          </TXDFlex>
                          <TXDFlex variant="column" className=" gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.AddDt
                              )}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.RequestDt
                              )}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.ApplicableDt
                              )}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.EftDt
                              )}
                            </TXDText>
                          </TXDFlex>
                          <TXDFlex variant="column" className="gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              Coding Reason
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              Coding Description
                            </TXDText>
                          </TXDFlex>
                          <TXDFlex variant="column" className=" gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              {transactionDetail?.TransactionData.CodingReason}
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-900 uppercase text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                              size="xs"
                            >
                              {findDescriptionByCodingReason(
                                transactionDetail?.TransactionData.CodingReason
                              )}
                            </TXDText>
                          </TXDFlex>
                        </TXDBox>
                      </TXDFlex>
                    </TXDBox>
                  </TXDBox>
                </TXDFlex>
              )}
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShowTransacationModal;
