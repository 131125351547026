import React from "react";
import { TXDBox, TXDFlex, TXDText } from "@supporttxend/txd-components";

const TransactionSkeleton: React.FC = () => {
  const shimmerStyle = {
    background: "linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%)",
    backgroundSize: "1000px 104px",
    animation: "shimmer 1.2s linear infinite",
  };
  const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -450px 0;
      }
      100% {
        background-position: 450px 0;
      }
    }
  `;
  return (
    <TXDBox className="inline-block w-full max-w-7xl   overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
      <TXDFlex variant="column" className="p-2 space-y-4">
        <TXDText
          className="shimmer bg-gray-300 h-12 w-full rounded"
          style={shimmerStyle}
        ></TXDText>
        <style>{shimmerKeyframes}</style>

        <TXDText
          className="shimmer bg-gray-300 h-20 w-full rounded"
          style={shimmerStyle}
        ></TXDText>
        <TXDText
          className="shimmer bg-gray-300 h-40 w-full rounded"
          style={shimmerStyle}
        ></TXDText>
      </TXDFlex>
    </TXDBox>
  );
};

export default TransactionSkeleton;
