import React, { useState, useEffect } from "react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDTable,
} from "@supporttxend/txd-components";
import AddNewParticipantModal from "../layouts/modals/AddNewParticipantModal";
import { NavLink, useParams } from "react-router-dom";
import UploadFileModal from "../layouts/modals/UploadFileModal";
import TableSkeleton from "@/layouts/skeletons/TableSkeleton";
import { setTotalParticipants } from "../redux/slices/participantsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import ShowCompanyInfoModal from "@/layouts/modals/ShowCompanyInfoModal";
import EditParticipantProfileModal from "@/layouts/modals/EditParticipantProfileModal";
import { IParticipantTable } from "@/interfaces";
import {
  formatSocialSecurity,
  getHumanReadableStatus,
} from "@/utils/componentUtils";
import { RO_CONSTANTS } from "@/constants/RolloverConstants";
import useFetchUserList from "@/services/useFetchUserList";
import { resetForm } from "@/redux/slices/participantFormSlice";

const ParticipantsTable: React.FC<IParticipantTable> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { permissionsList } = props.componentPermissions;
  const [selectedAdminId, setSelectedAdminId] = useState<number | null>(null);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [showCompany, setShowCompany] = useState(false);
  const { id } = useParams<{ id: string }>();
  if (!id) {
    <div>No Id Found</div>;
  }
  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = id || defaultId;
  const { users, loading, refetch } = useFetchUserList(
    RO_CONSTANTS.PORTFOLIO_TYPE_ID.PLAN_PARTICIPANT,
    effectiveId
  );
  const [open, setOpen] = useState(false);

  const openAddParticipantModalBox = () => {
    dispatch(resetForm());
    setOpen(true);
  };

  const [upload, setUpload] = useState(false);

  useEffect(() => {
    if (users) {
      dispatch(setTotalParticipants(users.length));
    }
  }, [users, dispatch]);

  const handleEditClick = (portfolioPermId: number) => {
    const selectedParticipant = users.find(
      (user) => user.PortfolioPermId === portfolioPermId
    );
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
    setSelectedAdminId(selectedParticipant);
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
  };

  const onUpdate = () => {
    refetch().then(() => {
      // console.log("Data refetched after update");
      setEditModal(false);
    });
  };

  const formatParticipantsData = (data: any[]) => {
    return data.map((participant) => ({
      id: participant.PortfolioPermId || "N/A",
      participants: (
        <NavLink
          to={`/participant/${participant.PortfolioPermId}`}
          className="font-bold"
        >
          {underlineFirstFourLetters(participant.PortfolioFirstName)}{" "}
          {participant.PortfolioLastName || "N/A"}
        </NavLink>
      ),
      socialSecurity:
        formatSocialSecurity(participant.PortfolioTaxIdentifier) || "N/A",
      assets: participant.PortfolioAssets
        ? `$ ${participant.PortfolioAssets.toLocaleString()}`
        : "$ 0",
      status: getHumanReadableStatus(participant.PortfolioStatus),
      actions: (
        <TXDFlex className="gap-2" alignItems="center">
          {permissionsList.read ? (
            <TXDBox className="tooltip-wrapper" data-tooltip="View">
              <FontAwesomeIcon
                icon={faEye}
                className="cursor-pointer text-[#00AFD8]"
                onClick={() => {
                  setSelectedAdminId(participant.PortfolioPermId);
                  setShowCompany(true);
                }}
              />
            </TXDBox>
          ) : (
            <></>
          )}
          {permissionsList.update ? (
            getHumanReadableStatus(participant.PortfolioStatus) == "Active" ? (
              <></>
            ) : (
              <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="cursor-pointer text-[#38D6B5]"
                  onClick={() => handleEditClick(participant.PortfolioPermId)}
                />
              </TXDBox>
            )
          ) : (
            <></>
          )}
        </TXDFlex>
      ),
    }));
  };
  const onParticipantAdded = () => {
    refetch(); // Refetch the participant data
  };

  const underlineFirstFourLetters = (name: string) => {
    if (!name) return "Unknown";
    const firstFour = name.slice(0, 4);
    const rest = name.slice(4);
    return (
      <span>
        <span className="border-b-2 border-[#4EC6E5]">{firstFour}</span>
        {rest}
      </span>
    );
  };

  const tableHead = [
    "id",
    "participants",
    "Social Security",
    "Assets",
    "Status",
    "Actions",
  ];
  const tableData = formatParticipantsData(users);

  return (
    <TXDBox className="shadow-lg mb-36 bg-[#ffffff] mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
      <TXDBox className="overflow-x-auto">
        {loading ? (
          <table className="min-w-full divide-y divide-gray-300">
            <TableSkeleton />
          </table>
        ) : (
          <TXDTable
            filteration={true}
            pagination={true}
            entries={true}
            tHead={tableHead}
            tRow={tableData}
            title="Participants"
          />
        )}
        {permissionsList.write ? (
          <TXDFlex justifyContent="end" className=" py-4  px-4 gap-4 bg-white">
            {/* <TXDBox className="tooltip-wrapper" data-tooltip="Add"> */}
            <TXDButton
              className="bg-white !text-[#4EC6E5] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5] text-medium font-semibold"
              radius="none"
              variant="primary"
              onClick={openAddParticipantModalBox}
            >
              <FontAwesomeIcon className="text-xs" icon={faPlus} /> Add
              Participant
            </TXDButton>
            {/* </TXDBox> */}
            {/* <TXDBox className="tooltip-wrapper" data-tooltip="Upload"> */}
            <TXDButton
              className="bg-white !text-[#4EC6E5] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5] text-medium font-semibold"
              radius="none"
              variant="primary"
              onClick={() => setUpload(true)}
            >
              <FontAwesomeIcon className="text-xs" icon={faUpload} /> Upload
              Participant
            </TXDButton>
            {/* </TXDBox> */}
          </TXDFlex>
        ) : (
          <></>
        )}

        {open && (
          <AddNewParticipantModal
            modalOpen={open}
            closeModal={() => setOpen(false)}
            sponsorId={id}
            onParticipantAdded={onParticipantAdded}
          />
        )}
        {upload && (
          <UploadFileModal
            modalOpen={upload}
            closeModal={() => setUpload(false)}
          />
        )}
        {showCompany && selectedAdminId !== null && (
          <ShowCompanyInfoModal
            modalOpen={showCompany}
            closeModal={() => setShowCompany(false)}
            identifier={selectedAdminId}
          />
        )}
        {editModal && currentPortfolioPermId !== null && (
          <EditParticipantProfileModal
            modalOpen={editModal}
            closeModal={() => setEditModal(false)}
            userProfile={selectedAdminId}
            onUpdate={onUpdate}
            portfolioPermId={currentPortfolioPermId}
          />
        )}
      </TXDBox>
    </TXDBox>
  );
};

export default ParticipantsTable;
